<template>
  <div
    class="d-flex justify-content-center align-items-center flex-column payment-success text-center"
  >
    <h1>
      {{ paymentSuccessLocale.heading }}
    </h1>
    <i18n class="mb-0 text-gray" path="payments.success.text" tag="p">
      <br class="d-none d-sm-block" />
    </i18n>
    <img
      src="../../../assets/images/payment/celebration.png"
      alt="illustration"
      class="img-fluid"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    paymentSuccessLocale() {
      return this.$t("payments.success");
    },
    ...mapGetters("auth", ["user"]),
  },
  created() {
    setTimeout(() => {
      this.$router.replace(`/user/profile`);
    }, 2000);
  },
};
</script>

<style lang="scss">
body {
  background-color: #ffff !important;
}

.payment-success {
  height: calc(100vh - 50px);
}
</style>
